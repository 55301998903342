<template>
  <app-overlay>
    <searchable-table
      :items="customers"
      :fields="fields"
      hover
      @row-clicked="showDetails"
    >
      <template #modalbutton>
        <app-button
          text="Kişi Ekle"
          size="md"
          icon="PlusIcon"
          @click="$showAppSidebar('Kişi Ekle', indCustomerModal)"
        />
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import indCustomerModal from './modal.vue'
import details from './details/details.vue'

export default {
  data: () => ({
    fields: [
      { key: 'fullname', label: 'İsim' },
      { key: 'tcNo', label: 'TC Kimlik' },
      { key: 'phone', label: 'Telefon' },
      { key: 'company', label: 'Firma' },
    ],
    indCustomerModal,
  }),
  computed: {
    customers() {
      return this.$store.getters.individualCustomers
    },
  },
  mounted() {
    this.$store.dispatch('getIndCustomers')
  },
  methods: {
    showDetails(item) {
      this.$showAppModal({
        title: 'Detaylar',
        component: details,
        item,
        size: 'custom',
        centered: false,
      })
    },
  },
}
</script>

<style></style>
