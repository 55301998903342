<template>
  <b-custom-tabs :tabs="tabs" />
</template>

<script>
import userInfo from './userInfo.vue'

export default {
  data() {
    return {
      tabs: [{ title: 'Kişi Bilgileri', component: userInfo }, { title: 'İşlemler' }],
    }
  },
}
</script>

<style></style>
