<template>
  <div v-if="user">
    <b-col class="p-0" md="6">
      <b-card>
        <app-detail-table :fields="fields" :data="user" />
        <hr />
        <app-button icon="RefreshCcwIcon" class="mt-50" text="Yeni Şifre Gönder" block @click="refreshPassword" />
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "fullname", label: "İsim", icon: "UserIcon" },
        { key: "tcNo", label: "TC Kimlik", icon: "FileIcon" },
        { key: "phone", label: "Telefon", icon: "PhoneCallIcon" },
        { key: "address", label: "Adres", icon: "MapPinIcon" },
        { key: "company", label: "Firma", icon: "FileIcon" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters?.appModalItem;
    },
  },
  methods: {
    refreshPassword() {
      this.$axios
        .patch(`/individual-customer/refresh-password/${this.user._id}`)
        .then((res) => {
          this.$emitter.$emit("Notification", {
            variant: "success",
            title: "Şifre Yenilendi.",
          });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
          });
        });
    },
  },
};
</script>

<style></style>
